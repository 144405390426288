import React, { useState, useEffect, useRef, useContext } from 'react';

// - react-bootstrap
import {
  Col, Row, Table, Pagination, FormControl
} from 'react-bootstrap';

import Dropdown from 'react-bootstrap/Dropdown';

// - data
import federations from 'assets/federations';

// - Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons';

// - styles
import {
  Form, Input, Select, Arrow, Button,
} from '../styles/federationsTableStyles';

// - tradução
import { LanguageContext } from 'containers/Language';

const origin = process.env.REACT_APP_BASE_URL;

console.log({origin})

function FederationsTable() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  const pageSize = 50;

  // const [filter, setFilter] = useState('All');
  const [page, setPage] = useState(1);
  const [federationsList, setFederationsList] = useState([]);

  // Estado para armazenar a lista filtrada
  const [filteredFederations, setFilteredFederations] = useState([]);

  /* const [country, setCountry] = useState('');
  const [name, setName] = useState('');
  const [ioc, setIoc] = useState(''); */
  
  // Estado que armazena os valores dos 3 inputs
  const [filters, setFilters] = useState({
    country: '',
    name: '',
    ioc: '',
  });

  // Função única para atualizar os inputs
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFilters((prevState) => ({
      ...prevState,
      [id]: value,
    }))
  };

  const tableRef = useRef(null);
  const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: tableRef.current.offsetTop });

  useEffect(() => {
    //setFederationsList(federations.slice(0, pageSize));
    // setFilter('All');
    fetch(`${origin}/api/federations`)
      .then(async (response) => {
        const json = await response.json();
        console.log({json})
        // Ordenando em ordem alfabética com base na propriedade "country"
        const jsonSorted = json.sort((a, b) => a.country.localeCompare(b.country));
        setFederationsList(jsonSorted.slice(0, pageSize))
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

 /*  useEffect(() => {
    console.log(page);
    const start = (page - 1) * pageSize;
    const finish = page * pageSize;

    setFederationsList(federationsList.slice(start, finish));
  }, [page]); */

  // useEffect(() => {
  //   setFederationsList([]);

  //   const filtered = federationsList.map((n) => ({ ...n, filtered: n.category.includes(filter) }));

  //   setFederationsList(filtered);
  // }, [filter]);

  // Quando a lista original for carregada, inicializa a lista filtrada com os mesmos dados
  useEffect(() => {
    setFilteredFederations(federationsList);
  }, [federationsList]);

  // Função para tratar o envio do formulário de filtros
  const handleSubmit = (e) => {
    e.preventDefault();

    // Obtem os valores dos inputs
    /* const country = e.target.elements.country.value;
    const name = e.target.elements.name.value.trim().toLowerCase();
    const ioc = e.target.elements.ioc.value.trim().toLowerCase(); */

    console.log(filters.country, filters.name, filters.ioc);

    const country = filters.country;
    const name = filters.name;
    const ioc = filters.ioc;

    console.log({ioc})

    // Aplica o filtro baseado nos valores informados.
    // Se algum campo estiver vazio, ele não será usado no filtro.
    const filtered = federationsList.filter((federation) => {
      const matchCountry = country ? federation.country === country : true;
      const matchName = name
        ? federation.nationalFederation.toLowerCase().includes(name.toLowerCase())
        : true;
      const matchIOC = ioc
        ? federation.IOCCode.toLowerCase().includes(ioc.toLowerCase())
        : true;
      return matchCountry && matchName && matchIOC;
    });

    setFilteredFederations(filtered);
  };

  function getFlagEmoji(countryCode) {
    // Converte para maiúsculas para garantir que as letras sejam de A a Z
    countryCode = countryCode.toUpperCase();
    const OFFSET = 0x1F1E6 - 65; // 0x1F1E6 corresponde à letra 'A'
    const flag = countryCode.split('').map(letter => {
      return String.fromCodePoint(letter.charCodeAt(0) + OFFSET);
    }).join('');
    return flag;
  }

  const [showPopup, setShowPopup] = useState(false);
  const textRef = useRef(null); // Referência para calcular posição

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-around">
          <Col lg="4" md="6" sm="12" style={{ position: 'relative' }}>
            <label htmlFor="country">Country</label>
            <Select 
              id="country" 
              className="d-block"
              value={filters.country}
              onChange={handleInputChange}
            >
              <option value="">All Countries</option>
              {federationsList.map((federation) => (
                <option key={federation.IOCCode + Math.random()} value={federation.country}>{federation.country}</option>
              ))}
            </Select>
            <Arrow>
              <FontAwesomeIcon
                icon={faCaretDown}
                size="lg"
                color="white"
              />
            </Arrow>

          </Col>
          <Col lg="3" md="6" sm="12">
            <label htmlFor="name">Name</label>
            <Input 
              id="name" 
              type="text" 
              placeholder="Type here the name" 
              className="d-block" 
              value={filters.name}
              onChange={handleInputChange}
            />
          </Col>
          <Col lg="3" md="6" sm="12">
            <label htmlFor="ioc">IOC Code</label>
            <Input 
              id="ioc" 
              type="text" 
              placeholder="Type here the code" 
              className="d-block" 
              value={filters.ioc}
              onChange={handleInputChange}
            />
          </Col>
          <Col lg="2" md="6" sm="12" className="d-flex align-items-end justify-content-end">
            <Button type="submit">
              Search
              <FontAwesomeIcon
                icon={faSearch}
                size="lg"
                color="white"
                style={{ marginLeft: 10 }}
              />
            </Button>
          </Col>
        </Row>
      </Form>

      <Row>
        <Col>
          <Table striped bordered hover ref={tableRef} >
            <thead>
              <tr>
                {/* <th>Flag</th> */}
                <th style={{padding: '12px'}}>IOC Code</th>
                <th style={{padding: '12px'}}>Country</th>
                <th style={{padding: '12px'}}>National Federation</th>
              </tr>
            </thead>
            <tbody>
              {filteredFederations.map((federation) => (
                <tr key={federation.IOCCode + Math.random()}>
                  {/* <td>{getFlagEmoji(federation.IOCCode)}</td> */}
                  <td style={{padding: '12px'}}>{federation.IOCCode}</td>
                  <td style={{padding: '12px'}}>{federation.country}</td>
                  {/* <td style={{padding: '12px'}}>{federation.nationalFederation}</td> */}
                  <td style={{padding: '12px', position: 'relative'}}>
                    <span 
                      //style={{textDecoration: 'underline'}}
                      style={{color: '#007BFF', cursor: 'pointer'}}
                      onClick={() => {
                        if (showPopup === federation.nationalFederation) {
                          setShowPopup('')
                        } else {
                          setShowPopup(federation.nationalFederation)
                        }
                        console.log('nationalFederation', federation.nationalFederation)
                      }}
                    >
                      {federation.nationalFederation}
                    </span>

                    {console.log({showPopup})}

                    {/* Popup com informações de contato */}
                    {showPopup === federation?.nationalFederation && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '100%', // Posiciona abaixo do texto
                          right: 0,
                          backgroundColor: 'white',
                          border: '1px solid #ccc',
                          padding: '10px',
                          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                          zIndex: 1000,
                          width: '65vw',
                          maxWidth: '500px'
                        }}
                      >
                        <p><strong>
                          {userLanguage === 'es' 
                      ? 'Presidente' 
                      : 'President'}:</strong><br /> {federation?.president}</p>
                        <p><strong>{userLanguage === 'es' 
                      ? 'Secretario General' 
                      : 'General Secretary'}:</strong><br /> {federation?.director}</p>
                        <p><strong>{userLanguage === 'es' 
                      ? 'Teléfono' 
                      : 'Phone'}:</strong><br /> {federation?.phone}</p>
                        <p><strong>E-mail:</strong><br /> {federation?.email}</p>
                        <p><strong>{userLanguage === 'es' 
                      ? 'Sitio Web' 
                      : 'Website'}:</strong><br /> <a href={federation?.website} target="_blank" rel="noopener noreferrer">{federation?.website}</a></p>
                      </div>
                    )}
                  </td>
                </tr>
              ))}

            </tbody>
          </Table>

        </Col>
      </Row>
      {/* <Row>
        <Col className="d-flex justify-content-center" style={{ padding: '20px 0 80px' }}>
          <Pagination>
            <Pagination.First onClick={() => { setPage(1); executeScroll(); }} />
            <Pagination.Prev disabled={page === 1} onClick={() => { setPage(page - 1); executeScroll(); }} />
            {[...Array(4)].map((_, i) => (
              <Pagination.Item active={page === (i + 1)} onClick={() => { setPage(i + 1); executeScroll(); }}>{i + 1}</Pagination.Item>
            ))}
            <Pagination.Next disabled={page === 4} onClick={() => { setPage(page + 1); executeScroll(); }} />
            <Pagination.Last onClick={() => { setPage(4); executeScroll(); }} />
          </Pagination>
        </Col>
      </Row> */}
      <Row style={{ paddingBottom: '120px' }}></Row>
    </>
  );
}

export default FederationsTable;
