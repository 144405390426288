import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';

// - react-bootstrap
import { Col, Row, Table /* , Pagination, */ } from 'react-bootstrap';

// - Texto traduzido
import { Text } from 'containers/Language';

// - data
//import federations from 'assets/federations';
//import disciplines from 'assets/disciplines';

// - Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  /* faCaretDown, faSearch, */ faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';

// - styles
import {
  /* Form, Input, Select, Arrow, Button, */ FilterButton,
  ButtonYearContainer,
} from '../styles/eventTableStyles';

// - tradução
import { LanguageContext } from '../../../containers/Language';

import { Link } from 'react-router-dom';

const origin = process.env.REACT_APP_BASE_URL;

function EventTable() {
  const [filter, setFilter] = useState('All');

  const [filterYear, setFilterYear] = useState('2022');

  const tableRef = useRef(null);

  const [events, setEvents] = useState([]);
  const [disciplines, setDisciplines] = useState([]);

  const filteredEvents = useMemo(
    () =>
      events.filter((event) =>
        filter === 'All' ? event : event.discipline === filter
      ),
    [events, filter]
  );

  const { userLanguage } = useContext(LanguageContext);

  console.log({filterYear})

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    setFilterYear(currentYear);

    fetch(`${origin}/api/disciplines`)
      .then(async (response) => {
        const json = await response.json();
        setDisciplines(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, []);

  useEffect(() => {
    fetch(`${origin}/api/events?year=${filterYear}`)
      .then(async (response) => {
        const json = await response.json();
        setEvents(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, [filterYear]);

  return (
    <>
      <Row style={{ paddingTop: 30, paddingBottom: 30 }}>
        <Col>
          <div className="labels">
            <FilterButton
              active={filter === 'All'}
              onClick={() => setFilter('All')}
            >
              All
            </FilterButton>
            {disciplines.map((discipline) => (
              <FilterButton
                key={discipline._id}
                active={filter === discipline._id}
                onClick={() => setFilter(discipline._id)}
              >
                {userLanguage === 'es'
                  ? discipline.spanish.title
                  : discipline.english.title}
              </FilterButton>
            ))}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <ButtonYearContainer>
            <button
              className="btn btn-light"
              onClick={() => {
                setFilterYear((prevState) => prevState - 1);
              }}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </button>
            <h4 style={{ margin: '0 40px' }}>{filterYear}</h4>
            <button
              className="btn btn-light"
              onClick={() => {
                setFilterYear((prevState) => prevState + 1);
              }}
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </button>
          </ButtonYearContainer>
        </Col>
      </Row>

      <Row>
        <Col>
          {filteredEvents.length !== 0 ? (
            <Table striped bordered hover ref={tableRef}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Event</th>
                  <th>Place</th>
                </tr>
              </thead>
              <tbody>
                {filteredEvents.map((event) => {
                  const options =
                    userLanguage === 'en'
                      ? {
                          year: 'numeric',
                          month: 'long',
                        }
                      : {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        };

                  let startDate = new Date(event.startDate);
                  let endDate = new Date(event.endDate);

                  let date;

                  if (startDate.getMonth() === endDate.getMonth()) {
                    if (userLanguage === 'es') {
                      date = `${startDate.getDate()} al ${endDate.toLocaleDateString(
                        userLanguage,
                        options
                      )}`;
                    } else {
                      date = `${startDate.getDate()}th to ${endDate.getDate()}th ${endDate.toLocaleDateString(
                        userLanguage,
                        { year: 'numeric', month: 'long' }
                      )}`;
                    }
                  } else {
                    if (userLanguage === 'es') {
                      date = `${startDate.toLocaleDateString(
                        userLanguage,
                        options
                      )} al ${endDate.toLocaleDateString(
                        userLanguage,
                        options
                      )}`;
                    } else {
                      date = `${startDate.getDate()}th ${startDate.toLocaleDateString(
                        userLanguage,
                        options
                      )} to ${endDate.getDate()}th ${endDate.toLocaleDateString(
                        userLanguage,
                        options
                      )}`;
                    }
                  }

                  return (
                    <tr key={event._id}>
                      <td>{date}</td>
                      <td>
                        <Link to={`/events/${event._id}`}>
                          {userLanguage === 'es'
                            ? event.spanish.title
                            : event.english.title}
                        </Link>
                      </td>
                      <td>{event.local}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div
              className="alert alert-warning text-center"
              style={{ fontSize: '16px', fontWeight: 'bold' }}
            >
              <Text content="noRecordsFound" />
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default EventTable;
