import styled from 'styled-components';

export const NewsTitle = styled.h2`
  font-weight: bold;
`;

export const NewsImg = styled.img`
  margin: 36px 0 8px;
  width: 100%;
  overflow: fit;
  /* img {
    width: 100%;
  } */
`;

export const NewsContent = styled.div`
  text-align: left;
  color: #333;
  font-size: 14px;

  & > div {
    margin-bottom: 15px;
    display: block;
  }

  /* & b {
    font-weight: bold;
    //font-size: 19px;
    font-size: 24px;
  } */

  & strong, h1, h2, h3 {
    font-weight: bold;
    //font-size: 19px;
    font-size: 16px;
  }

  & b {
    font-weight: bold;
  }

  & img {
    //margin: 20px auto;
    margin: 24px auto;
    display: block;
  }

  p, ul {
    margin-bottom: 16px;
  }
`;

export const PostInfo = styled.div`
  justify-self: left;
  width: 100%;
  margin-bottom: 16px;

  & span {
    font-size: 13px;
    color: #727272;
    margin-right: 15px;
    & svg {
      margin-right: 5px;
    }
  }
`;
