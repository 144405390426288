import React, { useState, useEffect, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useParams } from 'react-router-dom';

import PageHeader from 'components/PageHeader';

// - react-bootstrap
import { Container, Row, Col } from 'react-bootstrap';

// - api
//import { portalApi } from 'services/api';

// - styles
import { NewsTitle, NewsImg, NewsContent, PostInfo } from './styles';

// - tradução
import { LanguageContext } from 'containers/Language';

// -- FontAwesome
import { faUser, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//const imgBaseURL = 'http://www.canoagem.org.br/arquivos/noticias/destaque/';

//import Cover from 'assets/img/newsitem.png';

const origin = process.env.REACT_APP_BASE_URL;

function NewsItem() {
  const breadcrumbs = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'News',
    },
  ];

  const { userLanguage } = useContext(LanguageContext);

  const [newsItem, setNewsItem] = useState();

  const { id } = useParams();

  useEffect(() => {
    fetch(`${origin}/api/news/${id}`)
      .then(async (response) => {
        const json = await response.json();
        setNewsItem(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });
  }, [id]);

  function parseMarkdownToHtml(markdown) {
    // Substitui markdown por tags HTML básicas
    let html = markdown
    .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Negrito
    .replace(/\*(.*?)\*/g, '<em>$1</em>') // Itálico
    .replace(/\#\#\#\s(.*?)\n/g, '<strong>$1</strong>\n') // Título de nível 3
    .replace(/\#\#\s(.*?)\n/g, '<strong>$1</strong>\n') // Título de nível 2
    .replace(/\#\s(.*?)\n/g, '<strong>$1</strong>\n') // Título de nível 1
    .replace(/\[([^\]]+)\]\((https?:\/\/[^\s)]+)\)/g, '<a href="$2" target="_blank">$1</a>') // Links

    //.replace(/(^|\n)- (.*?)(\n|$)/g, '$1<ul><li>$2</li></ul>$3') // Bullet points
    .replace(/<\/li>\s*<li>/g, '') // Remove ul's separadas entre os itens da lista
    .replace(/^<li>/g, '<ul><li>') // Adiciona a <ul> antes do primeiro item
    .replace(/<\/li>$/g, '</li></ul>') // Adiciona a </ul> após o último item
    
    .replace(/<ul><\/ul>/g, '') // Remove ul vazios que podem ser criados
    .replace(/^(?!<.*?>)([^\n]+)$/gm, '<p>$1</p>'); // Envolve texto normal em <p>
    //.replace(/\n/g, '<br>'); // Quebra de linha

    // Junta listas consecutivas
    //html = html.replace(/<\/ul>\s*<ul>/g, '');
  
    return html;
  }

  function parserHtml(string) {
    if (!string) {
      return '';
    }

    if(string.startsWith('<')) {
      return ReactHtmlParser(string);
    }

    // Verifica se a string contém sintaxe markdown
    const isMarkdown = /[*_#`[\]-]/.test(string);


    if (isMarkdown) {
      const markdownHtml = parseMarkdownToHtml(string);

      //console.log({isMarkdown, markdownHtml})

      return ReactHtmlParser(markdownHtml);
    }

    //const paragraphs = newsItem?.english?.body?.split('\r\n');

    const paragraphs = string?.split('\r\n');

    return (
      <div>
        {paragraphs.map((paragraph, index) => (
          //<p key={index}>{paragraph}</p>
          <p key={index}>{ReactHtmlParser(paragraph)}</p>
        ))}
      </div>
    )
  }

  return (
    <>
      <PageHeader
        bannerTitle="Copac News"
        bannerSubtitle="Latest news about canoe"
        pageTitle="News"
        breadcrumbs={breadcrumbs}
        //img={Cover}
        page="news"
      />

      {!newsItem ? (
        <Container>
          <Row className="justify-content-center py-4 my-4"></Row>
        </Container>
      ) : (
        <Container>
          <Row className="justify-content-center py-4 my-4">
            <Col>
              <NewsTitle>
                {/* {newsItem.titulo} */}
                {userLanguage === 'es'
                  ? newsItem.spanish.title
                  : newsItem.english.title}
              </NewsTitle>
              <h5 style={{ color: '#727272' }}>
                {/* {newsItem.resumo} */}
                {userLanguage === 'es'
                  ? newsItem.spanish.subheadline
                  : newsItem.english.subheadline}
              </h5>
              <div>
                <NewsImg
                  src={`${origin}${newsItem.imagePath}`}
                  alt=""
                  className="img-responsive"
                />
              </div>
              {/* <div dangerouslySetInnerHTML={{ __html: newsItem.corpo }} /> */}
              <PostInfo>
                <span>
                  <FontAwesomeIcon icon={faUser} />
                  {newsItem.author}
                </span>
                <span>
                  <FontAwesomeIcon icon={faCalendar} />
                  {`
                    ${new Date(newsItem.createdAt).toLocaleDateString(
                      userLanguage
                    )}

                    ${new Date(newsItem.createdAt).toLocaleTimeString(
                      userLanguage
                    )}
                  `}
                </span>
              </PostInfo>
              <NewsContent>
                {/* {userLanguage === 'es'
                  ? ReactHtmlParser(newsItem.spanish.body)
                  : ReactHtmlParser(newsItem.english.body)} */}
                {userLanguage === 'es'
                  ? parserHtml(newsItem.spanish.body)
                  : parserHtml(newsItem.english.body)}
              </NewsContent>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default NewsItem;
